<template>
  <div class="list-view">
    <page-title>
      <el-button @click="addRow"
                 type="primary"
                 icon="el-icon-plus"
                 :disabled="fetchingData || downloading"
                 class="filter-item">
        新增
      </el-button>
    </page-title>
    <div class="filters-container">
      <created-at-picker v-model="createdAt"
                         @input="initData"
                         name="创建"></created-at-picker>
      <el-input placeholder="搜索..."
                v-model="search"
                clearable
                class="filter-item search"
                @keyup.enter.native="initData">
        <el-button @click="initData"
                   slot="append"
                   icon="el-icon-search"> </el-button>
      </el-input>
    </div>
    <el-table :data="tableData"
              row-key="id"
              ref="table"
              header-row-class-name="table-header-row"
              header-cell-class-name="table-header-cell"
              row-class-name="table-row"
              cell-class-name="table-cell"
              :height="tableHeight"
              v-loading="fetchingData">
      <el-table-column v-if="multipleMode"
                       align="center"
                       type="selection"
                       width="50"> </el-table-column>
      <el-table-column prop="id"
                       label="ID"
                       width="100"> </el-table-column>
      <el-table-column prop="name"
                       label="名称"> </el-table-column>
      <el-table-column prop="poster"
                       label="海报图">
        <template slot-scope="{ row }">
          <el-image style="width: 30px; height: 30px"
                    :src="row.poster"
                    fit="cover"
                    :preview-src-list="[row.poster]"></el-image>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="detail" label="详情图">
                <template slot-scope="{ row }">
                    <el-image
                        style="width: 30px; height: 30px"
                        :src="row.detail[0]"
                        fit="cover"
                        :preview-src-list="row.detail"
                    ></el-image>
                </template>
            </el-table-column> -->
      <el-table-column prop="inviteStartdatetime"
                       label="活动开始时间"></el-table-column>
      <el-table-column prop="inviteEnddatetime"
                       label="活动结束时间"></el-table-column>
      <el-table-column label="操作"
                       align="center"
                       fixed="right"
                       width="150">
        <template slot-scope="{ row }">
          <el-button @click="editRow(row)"
                     type="primary"
                     size="mini"
                     plain>编辑</el-button>
          <el-button @click="deleteRow(row)"
                     type="danger"
                     size="mini"
                     plain>删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-wrapper">
      <el-pagination background
                     @size-change="onSizeChange"
                     @current-change="onCurrentChange"
                     :current-page="page"
                     :page-sizes="[10, 20, 30, 40, 50]"
                     :page-size="pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="totalElements">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import pageableTable from '@/mixins/pageableTable';

export default {
    name: 'InvitationList',
    mixins: [pageableTable],
    data() {
        return {
            multipleMode: false,
            search: '',
            url: '/activityInvite/all',
            downloading: false,
            createdAt: ''
        };
    },
    computed: {
        selection() {
            return this.$refs.table.selection.map(i => i.id);
        }
    },
    methods: {
        beforeGetData() {
            return { search: this.search, query: { del: false, createdAt: this.createdAt } };
        },
        toggleMultipleMode(multipleMode) {
            this.multipleMode = multipleMode;
            if (!multipleMode) {
                this.$refs.table.clearSelection();
            }
        },
        addRow() {
            this.$router.push({
                path: '/invitationEdit',
                query: {
                    ...this.$route.query
                }
            });
        },
        editRow(row) {
            this.$router.push({
                path: '/invitationEdit',
                query: {
                    id: row.id
                }
            });
        },
        deleteRow(row) {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/activityInvite/del/${row.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.getData();
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        this.$message.error(e.error);
                    }
                });
        }
    }
};
</script>
<style lang="less" scoped>
</style>
