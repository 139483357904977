<template>
    <div class="list-view">
        <page-title>
            <el-button
                @click="addRow"
                type="primary"
                icon="el-icon-plus"
                :disabled="fetchingData || downloading"
                class="filter-item"
            >
                新增
            </el-button>
            <!-- <el-button
                @click="download"
                icon="el-icon-upload2"
                :loading="downloading"
                :disabled="fetchingData"
                class="filter-item"
            >
                导出
            </el-button> -->
        </page-title>
        <div class="filters-container">
            <el-input
                placeholder="搜索..."
                v-model="search"
                clearable
                class="filter-item search"
                @keyup.enter.native="initData"
            >
                <el-button @click="initData" slot="append" icon="el-icon-search"> </el-button>
            </el-input>
        </div>
        <el-table
            :data="tableData"
            row-key="id"
            ref="table"
            header-row-class-name="table-header-row"
            header-cell-class-name="table-header-cell"
            row-class-name="table-row"
            cell-class-name="table-cell"
            :height="tableHeight"
            v-loading="fetchingData"
        >
            <el-table-column v-if="multipleMode" align="center" type="selection" width="50"> </el-table-column>
            <el-table-column prop="id" label="ID" width="100"> </el-table-column>
            <el-table-column prop="name" label="姓名"> </el-table-column>
            <el-table-column prop="phone" label="手机"> </el-table-column>
            <el-table-column prop="code" label="邀请码"> </el-table-column>
            <el-table-column prop="canInviteNum" label="可邀请人数"> </el-table-column>
            <el-table-column prop="remark" label="备注"> </el-table-column>
            <el-table-column prop="inviteNum" label="邀请人数">
                <template slot="header" slot-scope="{ column }">
                    <sortable-header :column="column" :current-sort="sort" @changeSort="changeSort"> </sortable-header>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="350">
                <template slot-scope="{ row }">
                    <el-button @click="detail(row)" size="mini">邀请列表</el-button>
                    <el-button @click="showCode(row)" size="mini">二维码</el-button>
                    <el-button @click="editRow(row)" type="primary" size="mini" plain>编辑</el-button>
                    <el-button @click="deleteRow(row)" type="danger" size="mini" plain>删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination-wrapper">
            <!-- <div class="multiple-mode-wrapper">
                <el-button v-if="!multipleMode" @click="toggleMultipleMode(true)">批量编辑</el-button>
                <el-button-group v-else>
                    <el-button @click="operation1">批量操作1</el-button>
                    <el-button @click="operation2">批量操作2</el-button>
                    <el-button @click="toggleMultipleMode(false)">取消</el-button>
                </el-button-group>
            </div> -->
            <el-pagination
                background
                @size-change="onSizeChange"
                @current-change="onCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40, 50]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalElements"
            >
            </el-pagination>
        </div>
        <el-dialog :visible.sync="showDialog" title="邀请列表" width="800px" top="10vh">
            <div>
                <el-button
                    style="margin: 0 10px 10px 25px"
                    size="mini"
                    type="primary"
                    @click="downloadPhone"
                    :disabled="downloading"
                    >导出手机号</el-button
                >
                <created-at-picker
                    v-model="createdAt"
                    name="注册"
                    @input="getInviteInfo"
                    size="mini"
                ></created-at-picker>
            </div>
            <el-table :data="list" v-loading="dialogLoading" height="60vh">
                <el-table-column prop="inviteeUserId" label="注册id" width="80"></el-table-column>
                <el-table-column prop="inviteePhone" label="手机"></el-table-column>
                <el-table-column prop="inviterCode" label="邀请码"></el-table-column>
                <el-table-column prop="status" label="邀请状态">
                    <template slot-scope="{ row }">
                        <span v-if="row.status == 0">成功邀请</span>
                        <span v-if="row.status == 2">未注册</span>
                        <span v-if="row.status == 3">未实名</span>
                    </template>
                </el-table-column>
                <el-table-column prop="createdAt" label="邀请时间">
                    <template slot="header" slot-scope="{ column }">
                        <sortable-header :column="column" :current-sort="sort" @changeSort="changeSort"> </sortable-header>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>

        <el-dialog :visible.sync="showCodeDialog" title="二维码" width="400px" center>
            <vue-qrcode :value="codeValue" :options="{ width: 300, margin: 2 }" style="margin-left: 25px"></vue-qrcode>
            <div style="margin-left: 40px; font-size: 16px">右键点击二维码可保存</div>
        </el-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import pageableTable from '@/mixins/pageableTable';
import VueQrcode from '@chenfengyuan/vue-qrcode';
export default {
    name: 'InviteList',
    mixins: [pageableTable],
    components: { VueQrcode },
    data() {
        return {
            multipleMode: false,
            search: '',
            url: '/invite/all',
            downloading: false,
            showDialog: false,
            dialogLoading: false,
            list: [],
            showCodeDialog: false,
            codeValue: 'Hello, World!',
            inviteCode: '',
            inviterPhone: '',
            createdAt: ''
        };
    },
    computed: {
        selection() {
            return this.$refs.table.selection.map(i => i.id);
        }
    },
    methods: {
        beforeGetData() {
            return { search: this.search, query: { del: false } };
        },
        toggleMultipleMode(multipleMode) {
            this.multipleMode = multipleMode;
            if (!multipleMode) {
                this.$refs.table.clearSelection();
            }
        },
        addRow() {
            this.$router.push({
                path: '/inviteEdit',
                query: {
                    ...this.$route.query
                }
            });
        },
        editRow(row) {
            this.$router.push({
                path: '/inviteEdit',
                query: {
                    id: row.id
                }
            });
        },
        download() {
            this.downloading = true;
            this.$axios
                .get('/invite/excel', {
                    responseType: 'blob',
                    params: { size: 10000 }
                })
                .then(res => {
                    console.log(res);
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', res.headers['content-disposition'].split('filename=')[1]);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log(e);
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        },
        operation1() {
            this.$notify({
                title: '提示',
                message: this.selection
            });
        },
        operation2() {
            this.$message('操作2');
        },
        deleteRow(row) {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/invite/del/${row.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.getData();
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        this.$message.error(e.error);
                    }
                });
        },
        detail(row) {
            this.list = [];
            this.createdAt = '';
            this.showDialog = true;
            this.inviteCode = row.code;
            this.inviterPhone = row.phone;
            this.getInviteInfo();
        },
        getInviteInfo() {
            this.dialogLoading = true;
            this.$http.post(
                '/invite/getInviteListByPhone/' + this.inviterPhone,
                { size: 10000, sort: 'id,desc' },
               { body: 'json' }
            ).then(result => {
                this.list = result.inviteDataList;
                this.dialogLoading = false;
            })
            // this.$http
            //     .post(
            //         '/user/all',
            //         { size: 10000, sort: 'id,desc', query: { inviteCode: this.inviteCode, createdAt: this.createdAt } },
            //         { body: 'json' }
            //     )
            //     .then(res => {
            //         this.list = res.content;
            //         this.dialogLoading = false;
            //     });
        },
        showCode(row) {
            this.codeValue = this.$baseUrl+'/9th/?inviteCode=' + row.code;
            if (this.$store.state.projectId == 1) {
                this.codeValue = this.$baseUrl+ '/cosmos/?inviteCode=' + row.code;
            }

            this.showCodeDialog = true;
        },
        downloadPhone() {
            this.downloading = true;
            this.$axios
                .post(
                    '/user/exportInvite',
                    { size: 10000, sort: 'id,desc', query: { inviteCode: this.inviteCode, createdAt: this.createdAt } },
                    {
                        responseType: 'blob'
                    }
                )
                .then(res => {
                    console.log(res);
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', '手机号.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log(e);
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        }
    }
};
</script>
<style lang="less" scoped>
</style>
